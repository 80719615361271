<template>
  <div>
    <section class="blockElement bg-white userLogin">
      <div class="container-fluid">
        <div class="row vh-100">
          <div class="col-12 col-lg-4 bg-image">
            <div class="loginLogo">
              <router-link to="/"
                ><img src="assets/images/logo.svg" alt="Portfolios"
              /></router-link>
            </div>
            <div class="loginSlider hideMobile">
              <img src="assets/images/login.svg" alt="login" title="Login" />
            </div>
            <div class="copyRightlogin hideMobile text-center">
              <p class="text-white">
                ©2022 www.portfolios.com All Rights Reserved Terms & Privacy
              </p>
            </div>
          </div>
          <div class="col-12 col-lg-8">
            <div class="createLogin text-end">
              <router-link to="/register" class="border-btn"
                >Create an Account</router-link
              >
            </div>
            <div class="loginPortfolio">
              <h3 class="text-center mb-5">Log into Portfolios</h3>
              <Form class="login" @submit="submit">
                <div class="form-group">
                  <label>Email Address</label>
                  <Field
                    type="text"
                    class="form-control"
                    name="Email"
                    rules="required"
                    v-model="form.email"
                  />
                  <ErrorMessage
                    as="paragraph"
                    name="Email"
                    class="text-danger mb-0"
                  />
                </div>
                <div class="form-group pb-2">
                  <label>Password</label>
                  <Field
                    type="password"
                    class="form-control"
                    name="Password"
                    rules="required"
                    v-model="form.password"
                  />
                  <ErrorMessage
                    as="paragraph"
                    name="Password"
                    class="text-danger mb-0"
                  />
                  <span class="text-end mt-1 d-block">
                    <router-link to="/forgot-password" class="green"
                      >Forgot Password</router-link
                    >
                  </span>
                </div>
                <div class="form-group">
                  <button class="grd_btn w-100">LOG IN</button>
                </div>
              </Form>
              <div class="or text-center mb-4 d-none">
                <p class="mb-0 d-inline-flex">OR</p>
              </div>
              <div class="socialShare d-none">
                <a
                  href="javascript:void(0)"
                  class="border-btn d-flex align-items-center justify-content-center"
                  ><img
                    class="me-3"
                    src="assets/images/google.svg"
                    alt="Google"
                    title="Continue with Google"
                  />Continue with Google</a
                >
                <a
                  href="javascript:void(0)"
                  class="border-btn d-flex align-items-center justify-content-center"
                  ><img
                    class="me-3"
                    src="assets/images/apple.svg"
                    alt="Apple"
                    title="Continue with Apple"
                  />Continue with Apple</a
                >
                <a
                  href="javascript:void(0)"
                  class="border-btn d-flex align-items-center justify-content-center"
                  ><img
                    class="me-3"
                    src="assets/images/facebook-cir.svg"
                    alt="Facebook"
                    title="Continue with Facebook"
                  />Continue with Facebook</a
                >
              </div>
              <!-- <div class="register text-center">
                <router-link to="/register" class="green underline"
                  >CAN’T LOG IN?</router-link
                >
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { useMainStore } from "@/store";
import { Form, Field, ErrorMessage } from "vee-validate";

export default {
  setup() {
    const store = useMainStore();
    return { store };
  },
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
    };
  },
  methods: {
    submit() {
      this.store.login(this.form, true,this);
    },
  },
};
</script>
