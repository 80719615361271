<template>
  <div>
    <section class="blockElement bg-white userLogin">
      <div class="container-fluid">
        <div class="row vh-100">
          <div class="col-12 col-lg-4 bg-image">
            <div class="loginLogo">
              <router-link to="/"
                ><img src="assets/images/logo.svg" alt="Portfolios"
              /></router-link>
            </div>
            <div class="loginSlider hideMobile">
              <img src="assets/images/login.svg" alt="login" title="Login" />
            </div>
            <div class="copyRightlogin hideMobile text-center">
              <p class="text-white">
                ©2022 www.portfolios.com All Rights Reserved Terms & Privacy
              </p>
            </div>
          </div>
          <div class="col-12 col-lg-8">
            <div class="createLogin text-end">
              <router-link to="/login" class="border-btn">Log in</router-link>
            </div>
            <div class="loginPortfolio register">
              <div class="main_title text-center mb-md-4">
                <h3 v-if="!store.transactionID" class="mb-4">
                  Create Your Account
                </h3>
                <h3 v-else class="mb-4">Verify</h3>
                <p v-if="!store.transactionID" class="mb-0">
                  Let’s get you all set up so you can verify your personal
                  account and begin setting up your profile.
                </p>
                <p v-else class="mb-0">
                  Please enter OTP received on {{ form.email }}
                </p>
              </div>
              <Form
                v-if="!store.transactionID"
                class="login d-flex align-items-start justify-content-between flex-wrap"
                @submit="submit"
              >
                <!--Click submit add class "was-validated"-->
                <div class="form-group">
                  <label>Username</label>
                  <Field
                    type="text"
                    placeholder="Enter username"
                    class="form-control"
                    name="username"
                    rules="required"
                    v-model="form.loginname"
                  />
                  <ErrorMessage
                    as="paragraph"
                    name="username"
                    class="text-danger mb-0"
                  />
                </div>
                <div class="form-group">
                  <label>First Name</label>
                  <Field
                    type="text"
                    placeholder="Enter name"
                    class="form-control"
                    name="First Name"
                    rules="required"
                    v-model="form.namef"
                  />
                  <ErrorMessage
                    as="paragraph"
                    name="First Name"
                    class="text-danger mb-0"
                  />
                </div>
                <div class="form-group pb-2">
                  <label>Last Name</label>
                  <Field
                    type="text"
                    placeholder="Enter name"
                    class="form-control"
                    name="Last Name"
                    rules="required"
                    v-model="form.namel"
                  />
                  <ErrorMessage
                    as="paragraph"
                    name="Last Name"
                    class="text-danger mb-0"
                  />
                </div>
                <div class="form-group">
                  <label>Email Address</label>
                  <Field
                    type="email"
                    placeholder="Enter name"
                    class="form-control"
                    name="Email"
                    rules="required|email"
                    v-model="form.email"
                  />
                  <ErrorMessage
                    as="paragraph"
                    name="Email"
                    class="text-danger mb-0"
                  />
                </div>
                <div class="form-group pb-2">
                  <label>Password</label>
                  <Field
                    type="password"
                    placeholder="Enter password"
                    class="form-control"
                    name="Password"
                    rules="required|min:7"
                    v-model="form.password"
                  />
                  <ErrorMessage
                    as="paragraph"
                    name="Password"
                    class="text-danger mb-0"
                  />
                </div>
                <div class="form-group pb-2">
                  <label>Confirm Password</label>
                  <Field
                    type="password"
                    placeholder="Confirm password"
                    class="form-control"
                    name="Confirm Password"
                    rules="required|confirmed:@Password|min:7"
                    v-model="form.confirmPassword"
                  />
                  <ErrorMessage
                    as="paragraph"
                    name="Confirm Password"
                    class="text-danger mb-0"
                  />
                </div>
                <div class="form-group pb-2 w-100 text-center">
                  <p class="f-16 mb-0">
                    By creating an account, you agree to our
                    <a href="javascript:void(0)" class="green underline"
                      >Terms of Services</a
                    >
                    and have read and understood the
                    <a href="javascript:void(0)" class="green underline"
                      >Privacy Policy</a
                    >.
                  </p>
                </div>
                <div class="form-group w-100">
                  <button class="grd_btn w-100">CREATE ACCOUNT</button>
                </div>
              </Form>
              <Form v-else class="login verify" @submit="verify">
                <!--Click submit add class "was-validated"-->
                <div class="form-group">
                  <!-- <label>OTP</label> -->
                  <Field
                    type="text"
                    placeholder="Enter OTP"
                    class="form-control"
                    name="OTP"
                    rules="required|min:6|max:6"
                    v-model="form.otp"
                  />
                  <ErrorMessage
                    as="paragraph"
                    name="OTP"
                    class="text-danger mb-0"
                  />
                </div>
                <div class="form-group w-100">
                  <button class="grd_btn w-100">Verify</button>
                </div>
              </Form>

              <div class="register text-center">
                <strong
                  >Already have an account?
                  <router-link to="/login" class="green underline"
                    >Log in</router-link
                  ></strong
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { useMainStore } from "@/store";
import { Form, Field, ErrorMessage } from "vee-validate";

export default {
  setup() {
    const store = useMainStore();
    return { store };
  },
  watch: {
    "store.transactionID"(newVal, old) {
      this.form.transaction_id = newVal;
    },
  },
  mounted() {
    if (this.$route.query && this.$route.query.verify) {
      this.store.register({ verify: this.$route.query.verify }, true);
    }
  },
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      form: {
        namef: "",
        namel: "",
        email: "",
        password: "",
        confirmPassword: "",
        otp: "",
        transaction_id: null,
        loginname : ""
      },
    };
  },
  methods: {
    submit() {
      this.store.createLead(this.form, true);
    },
    verify() {
      this.store.register(this.form, true);
    },
  },
};
</script>
<style scoped>
.loginPortfolio.register form.verify .form-group {
  max-width: 100%;
}
</style>
