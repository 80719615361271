<template>
  <div>
    <section class="blockElement bg-white userLogin">
      <div class="container-fluid">
        <div class="row vh-100">
          <div class="col-12 col-lg-4 bg-image">
            <div class="loginLogo">
              <router-link to="/"
                ><img src="assets/images/logo.svg" alt="Portfolios"
              /></router-link>
            </div>
            <div class="loginSlider hideMobile">
              <img src="assets/images/login.svg" alt="login" title="Login" />
            </div>
            <div class="copyRightlogin hideMobile text-center">
              <p class="text-white">
                ©2022 www.portfolios.com All Rights Reserved Terms & Privacy
              </p>
            </div>
          </div>
          <div class="col-12 col-lg-8">
            <div class="createLogin text-end">
              <router-link to="/login" class="border-btn">Log in</router-link>
            </div>
            <div class="loginPortfolio">
              <div class="main_title text-center mb-md-4">
                <h3 class="mb-4">Forgot Password</h3>
                <p>
                  Enter your account’s email and we’ll send you an email to
                  reset the password
                </p>
              </div>
              <Form
                v-if="!form.hash"
                class="login"
                @submit="submit"
              >
                <!--Click submit add class "was-validated"-->
                <div class="form-group">
                  <label>Email Address</label>
                  <Field
                    type="text"
                    class="form-control"
                    name="Email"
                    rules="required"
                    v-model="form.email"
                  />
                  <ErrorMessage
                    as="paragraph"
                    name="Email"
                    class="text-danger mb-0"
                  />
                </div>
                <div class="form-group">
                  <button class="grd_btn w-100">SEND EMAIL</button>
                </div>
              </Form>
              <Form v-else class="login" @submit="verify">
                <!--Click submit add class "was-validated"-->
                <div class="form-group">
                  <label>Password</label>
                  <Field
                    type="password"
                    class="form-control"
                    name="Password"
                    rules="required"
                    v-model="form.password"
                  />
                  <ErrorMessage
                    as="paragraph"
                    name="Password"
                    class="text-danger mb-0"
                  />
                </div>
                <div class="form-group">
                  <label>Confirm Password</label>
                  <Field
                    type="password"
                    class="form-control"
                    name="Confirm Password"
                    rules="required"
                    v-model="form.cpassword"
                  />
                  <ErrorMessage
                    as="paragraph"
                    name="Confirm Password"
                    class="text-danger mb-0"
                  />
                </div>
                <div class="form-group">
                  <button class="grd_btn w-100">Update Password</button>
                </div>
              </Form>
              <!-- <div class="register text-center">
                                <router-link to="/login" class="green underline">BACK TO LOG IN</router-link>
                            </div> -->
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { useMainStore } from "@/store";
import { Form, Field, ErrorMessage } from "vee-validate";

export default {
  setup() {
    const store = useMainStore();
    return { store };
  },
  watch: {
    "store.transactionID"(newVal, old) {
      this.form.transaction_id = newVal;
    },
  },
  mounted() {
    if (this.$route.query && this.$route.query.hash) {
      this.form.hash = this.$route.query.hash;
    }
  },
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    return {
      form: {
        email: "",
        password: "",
        cpassword: "",
        hash: "",
      },
    };
  },
  methods: {
    submit() {
      this.store.forgotPassword(this.form, true);
    },
    verify() {
      this.store.forgotPasswordVerify(this.form, true);
    },
  },
};
</script>
