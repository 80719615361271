export const GLOBAL_BASE_URL = process.env.VUE_APP_API_BASE_URL

//===========================================store function api=========================================
export const EP_LOGIN = `${GLOBAL_BASE_URL}/api/login`
export const EP_CREATE_REGISTRATION_LEAD = `${GLOBAL_BASE_URL}/api/create/lead`
export const EP_REGISTER = `${GLOBAL_BASE_URL}/api/user/register`
export const EP_FORGOT_PASSWORD = `${GLOBAL_BASE_URL}/forgot/password`
export const EP_VERIFY_FORGOT_PASSWORD = `${GLOBAL_BASE_URL}/change/via/forgot/password`
export const EP_CHANGE_PASSWORD = `${GLOBAL_BASE_URL}/change/password`
export const EP_GET_NEWS = `${GLOBAL_BASE_URL}/get/news`
export const EP_GET_NEWS_DETAILS = `${GLOBAL_BASE_URL}/get/news/details`

export const EP_GET_FINANCIAL_CONTENT = `${GLOBAL_BASE_URL}/get/financial/content`
export const EP_GET_FINANCIAL_CONTENT_DETAIL = `${GLOBAL_BASE_URL}/get/financial/details`

export const EP_CALENDAR = `${GLOBAL_BASE_URL}/get/calendar`
export const EP_CALENDAR_TICKER_HISTORY = `${GLOBAL_BASE_URL}/get/ticker/history`
export const EP_GET_ECONOMIC_OVERVIEW = `${GLOBAL_BASE_URL}/get/economy/data`
export const EP_GET_TRENDING_NEWS = `${GLOBAL_BASE_URL}/get/trending/news`
export const EP_CREATE_TRADE_IDEA = `${GLOBAL_BASE_URL}/create/trade`
export const EP_GET_POPULAR_IDEAS = `${GLOBAL_BASE_URL}/get/popular/trade`
export const EP_GET_POPULAR_NEWS = `${GLOBAL_BASE_URL}/get/popular/news`
export const EP_GET_TRADE_IDEAS_LIST = `${GLOBAL_BASE_URL}/get/trade/list`
export const EP_GET_USER_PROFILE = `${GLOBAL_BASE_URL}/api/user/profile`
export const EP_GET_TRANDING_FINANCIAL_CONTENT = `${GLOBAL_BASE_URL}/get/trending/financial/content`
export const EP_TRENDING_ISINS = `${GLOBAL_BASE_URL}/get/markets/trend`
export const EP_GET_COUNTRY_ECONOMY_DATA = `${GLOBAL_BASE_URL}/get/country/economy/data`
export const EP_GET_COUNTRY_ECONOMY_HISTORY = `${GLOBAL_BASE_URL}/get/history`
export const EP_GET_COUNTRY_ECONOMY_MARKET_HISTORY = `${GLOBAL_BASE_URL}/get/market/history/data`
export const EP_GET_TRADE_IDEA_DETAIL = `${GLOBAL_BASE_URL}/get/trade/details`
export const EP_UPDATE_IDEAS = `${GLOBAL_BASE_URL}/update/like`
export const EP_GET_ANOTHER_USER_PROFILE = `${GLOBAL_BASE_URL}/api/user/profile/userID`
export const EP_ADD_COMMENT = `${GLOBAL_BASE_URL}/add/comment`
export const EP_GET_IDEAS_COMMENTS = `${GLOBAL_BASE_URL}/get/comments`
export const EP_FOLLOW_USER = `${GLOBAL_BASE_URL}/api/user/follow`
export const EP_UNFOLLOW_USER = `${GLOBAL_BASE_URL}/api/user/unfollow`
export const EP_USER_FOLLOWERS = `${GLOBAL_BASE_URL}/api/user/followers`
export const EP_UPDATE_USER_PROFILE = `${GLOBAL_BASE_URL}/api/update`
export const EP_GET_TRENDING_ISINS_LIST = `${GLOBAL_BASE_URL}/get/markets/trend/isin`
export const EP_GET_TRENDING_SYMBOL_EVENTS = `${GLOBAL_BASE_URL}/get/country/events`















