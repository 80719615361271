<template>
  <Header v-if="$route.path != '/chart' && $route.name != 'mobile_news_detail'" />
  <div
    id="main-wrapper"
    :class="[{ innerMarqueTop: $route.path != '/dashboard' }]"
  >
    <router-view />
  </div>
  <!-- <Footer v-if="$route.path != '/economic-overview' && $route.path != '/economic-overview'"/> -->
  <Footer
    v-if="
      $route.path != '/chart' &&
      $route.path != '/economic-view' &&
      $route.path != '/economic-overview' &&
      $route.path != '/gdp' &&
      $route.path != '/money-and-price' &&
      $route.path != '/markets' &&
      $route.path != '/business-consumer' &&
      $route.path != '/revenue-and-expenditure' &&
      $route.path != '/labour-and-health' &&
      $route.path != '/forex-and-trade' &&
      $route.path != '/latest-news' &&
      $route.name != 'mobile_news_detail'
    "
  />
</template>

<script>
import Header from "@/components/shared/header";
import Footer from "@/components/shared/footer";
import { ProviderCacheMixins } from "@/plugins/vue-provider-cache/mixin";
import { Const } from "@/plugins/vue-provider-cache/const.mod";

export default {
  mixins: [ProviderCacheMixins],
  data() {
    return {};
  },
  mounted() {
    this.subscribe(["EURUSD", "USDJPY"]);
  },
  components: { Header, Footer },
};
</script>
