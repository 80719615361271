<template>
    <div class="headerSection clearfix">
        <!-- <div class="left-nav f-left">
      <router-link to="/dashboard" class="logo-holder f-left">
        <img src="assets/images/logo.png" alt="" />
      </router-link>

     
    </div> -->
        <div class="top-header blackBG border-bottom" id="stickyHeader"
            :class="[{ innerMaginTop: $route.path != '/' }]">
            <div class="container-fluid">
                <nav class="navbar navbar-expand-lg navbar-dark p-0">
                    <router-link to="/" class="navbar-brand"><img class="d-block" src="/assets/images/logo.svg"
                            alt="Portfolios" /></router-link>
                    <button @click.prevent="(navCollpase = !navCollpase), addClass('')" class="navbar-toggler"
                        type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav"
                        aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div :class="navCollpase ? 'show' : ''"
                        class="collapse showNavbar navbar-collapse justify-content-between" id="navbarSupportedContent">
                        <ul class="navbar-nav mr-auto pl-5">
                            <li @click="addClass('remove')" class="nav-item">
                                <router-link class="nav-link" :class="[{ active: $route.path == '/chart' }]" to="/chart"
                                    target="_blank">Charts</router-link>
                            </li>
                            <li class="nav-item dropdown has-megamenu">
                                <a class="nav-link dropdown-toggle" href="javascript:void(0)"
                                    data-bs-toggle="dropdown">Markets</a>
                                <div class="dropdown-menu mKRight p-0" role="menu">
                                    <div class="position-relative min-800 megamenu bg-white">
                                        <div class="row">
                                            <div class="col-12 col-lg-6">
                                                <ul class="d-flex align-items-center flex-wrap">
                                                    <li @click="addClass('remove')" class="nav-item">
                                                        <router-link to="/market-trend" class="nav-link">
                                                            <span class="menuIcon flex-shrink-0">
                                                                <img src="assets/images/menu-icon/header-menu9.svg"
                                                                    alt="s1" title="Market Trend" />
                                                            </span>
                                                            <div class="flex-grow-1">
                                                                <h6 class="mb-1 medium">Market Trend</h6>
                                                                <p class="mb-0">
                                                                    This section keeps you up-to-date with the latest
                                                                    Breaking News, trending instruments...
                                                                </p>
                                                            </div>
                                                        </router-link>
                                                    </li>
                                                    <li @click="addClass('remove')" class="nav-item">
                                                        <router-link to="/market" class="nav-link">
                                                            <span class="menuIcon flex-shrink-0">
                                                                <img src="assets/images/menu-icon/header-menu5.svg"
                                                                    alt="s1" title="Market Trend" />
                                                            </span>
                                                            <div class="flex-grow-1">
                                                                <h6 class="mb-1 medium">Markets</h6>
                                                                <p class="mb-0">
                                                                    A view across all asset classes, ranked returns, and
                                                                    other time periods.
                                                                </p>
                                                            </div>
                                                        </router-link>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="col-12 col-lg-6">
                                                <ul class="d-flex align-items-center flex-wrap">
                                                    <li @click="addClass('remove')" class="nav-item">
                                                        <router-link to="/market-insights" class="nav-link">
                                                            <span class="menuIcon flex-shrink-0">
                                                                <img src="assets/images/menu-icon/header-menu2.svg"
                                                                    alt="s1" title="Market Trend" />
                                                            </span>
                                                            <div class="flex-grow-1">
                                                                <h6 class="mb-1 medium">Technical Analysis</h6>
                                                                <p class="mb-0">
                                                                    A detailed coverage of events, view on the market
                                                                    trend, technical analysis etc.
                                                                </p>
                                                            </div>
                                                        </router-link>
                                                    </li>
                                                    <li @click="addClass('remove')" class="nav-item">
                                                        <router-link to="/market" class="nav-link">
                                                            <span class="menuIcon flex-shrink-0">
                                                                <img src="assets/images/menu-icon/header-menu3.svg"
                                                                    alt="s1" title="Market Trend" />
                                                            </span>
                                                            <div class="flex-grow-1">
                                                                <h6 class="mb-1 medium">Exotic Pairs</h6>
                                                                <p class="mb-0">
                                                                    To diversify the portfolio, find more exotic
                                                                    currency pairs.
                                                                </p>
                                                            </div>
                                                        </router-link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li @click="addClass('remove')" class="nav-item">
                                <router-link class="nav-link" :class="[{ active: $route.path == '/trade-ideas' }]"
                                    to="/trade-ideas">Ideas</router-link>
                            </li>
                            <li @click="addClass('remove')" class="nav-item">
                                <router-link class="nav-link" :class="[{ active: $route.path == '/news' }]" to="/news">
                                    News</router-link>
                            </li>
                            <li class="nav-item dropdown has-megamenu">
                                <a class="nav-link dropdown-toggle" href="javascript:void(0)"
                                    data-bs-toggle="dropdown">Tools</a>
                                <div class="dropdown-menu smRight p-0" role="menu">
                                    <div class="position-relative min-400 megamenu bg-white">
                                        <div class="row">
                                            <div class="col-12">
                                                <ul class="d-flex align-items-center flex-wrap">
                                                    <!-- <li @click="addClass('remove')" class="nav-item">
                                                        <router-link to="/tools-and-analysis" class="nav-link">
                                                            <span class="menuIcon flex-shrink-0">
                                                                <img src="assets/images/menu-icon/header-menu8.svg"
                                                                    alt="s1" title="Market Trend" />
                                                            </span>
                                                            <div class="flex-grow-1">
                                                                <h6 class="mb-1 medium">Statistical Tools</h6>
                                                                <p class="mb-0">
                                                                    An economic calendar is a resource that allows
                                                                    traders...
                                                                </p>
                                                            </div>
                                                        </router-link>
                                                    </li> -->
                                                    <!-- <li @click="addClass('remove')" class="nav-item">
                                                        <router-link to="/chart-patterns" class="nav-link">
                                                            <span class="menuIcon flex-shrink-0">
                                                                <img src="assets/images/menu-icon/header-menu2.svg"
                                                                    alt="s1" title="Market Trend" />
                                                            </span>
                                                            <div class="flex-grow-1">
                                                                <h6 class="mb-1 medium">Chart Patterns</h6>
                                                                <p class="mb-0">
                                                                    An economic calendar is a resource that allows
                                                                    traders...
                                                                </p>
                                                            </div>
                                                        </router-link>
                                                    </li> -->
                                                    <li @click="addClass('remove')" class="nav-item w-100">
                                                        <router-link to="/economic-calendar" class="nav-link">
                                                            <span class="menuIcon flex-shrink-0">
                                                                <img src="assets/images/menu-icon/header-menu2.svg"
                                                                    alt="s1" title="Market Trend" />
                                                            </span>
                                                            <div class="flex-grow-1">
                                                                <h6 class="mb-1 medium">Economic Calendar</h6>
                                                                <p class="mb-0">
                                                                    Financial events and indicators from around the
                                                                    world are covered in the real-time...
                                                                </p>
                                                            </div>
                                                        </router-link>
                                                    </li>
                                                    <li @click="addClass('remove')" class="nav-item w-100">
                                                        <router-link to="/economy-overview" class="nav-link">
                                                            <span class="menuIcon flex-shrink-0">
                                                                <img src="assets/images/menu-icon/header-menu4.svg"
                                                                    alt="s1" title="Market Trend" />
                                                            </span>
                                                            <div class="flex-grow-1">
                                                                <h6 class="mb-1 medium">Economy Overview</h6>
                                                                <p class="mb-0">
                                                                    Get the latest snapshot of the economy, analyze and
                                                                    compare different indicators...
                                                                </p>
                                                            </div>
                                                        </router-link>
                                                    </li>
                                                </ul>
                                            </div>
                                            <!-- <div class="col-12 col-lg-6">
                                                <ul class="d-flex align-items-center flex-wrap">
                                                    <li @click="addClass('remove')" class="nav-item">
                                                        <router-link to="/indicator-list" class="nav-link">
                                                            <span class="menuIcon flex-shrink-0">
                                                                <img src="assets/images/menu-icon/header-menu1.svg"
                                                                    alt="s1" title="Market Trend" />
                                                            </span>
                                                            <div class="flex-grow-1">
                                                                <h6 class="mb-1 medium">Eco Indicators</h6>
                                                                <p class="mb-0">
                                                                    An economic calendar is a resource that allows
                                                                    traders to learn about important.
                                                                </p>
                                                            </div>
                                                        </router-link>
                                                    </li>
                                                    <li @click="addClass('remove')" class="nav-item">
                                                        <router-link to="/tools-and-analysis" class="nav-link">
                                                            <span class="menuIcon flex-shrink-0">
                                                                <img src="assets/images/menu-icon/header-menu1.svg"
                                                                    alt="s1" title="Market Trend" />
                                                            </span>
                                                            <div class="flex-grow-1">
                                                                <h6 class="mb-1 medium">Sentiments</h6>
                                                                <p class="mb-0">
                                                                    Traders combine market sentiment indicators
                                                                    with...
                                                                </p>
                                                            </div>
                                                        </router-link>
                                                    </li>
                                                    <li @click="addClass('remove')" class="nav-item">
                                                        <a href="javascript:void(0)" class="nav-link">
                                                            <span class="menuIcon flex-shrink-0">
                                                                <img src="assets/images/menu-icon/header-menu10.svg"
                                                                    alt="s1" title="Market Trend" />
                                                            </span>
                                                            <div class="flex-grow-1">
                                                                <h6 class="mb-1 medium">Calculators</h6>
                                                                <p class="mb-0">
                                                                    The Trading Calculator will show you all of
                                                                    the risks and possible...
                                                                </p>
                                                            </div>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <!-- <li class="nav-item dropdown has-megamenu">
                                <a class="nav-link dropdown-toggle" href="javascript:void(0)"
                                    data-bs-toggle="dropdown">More</a>
                                <div class="dropdown-menu mKRight p-0" role="menu">
                                    <div class="position-relative min-400 megamenu bg-white">
                                        <ul class="d-flex align-items-center flex-wrap">
                                            <li @click="addClass('remove')" class="nav-item">
                                                <a href="javascript:void(0)" class="nav-link">
                                                    <span class="menuIcon flex-shrink-0">
                                                        <img src="assets/images/menu-icon/header-menu6.svg" alt="s1"
                                                            title="Market Trend" />
                                                    </span>
                                                    <div class="flex-grow-1">
                                                        <h6 class="mb-1 medium">Forums</h6>
                                                        <p class="mb-0">
                                                            A forum is an online discussion board where people
                                                            can ask questions.
                                                        </p>
                                                    </div>
                                                </a>
                                            </li>
                                            <li @click="addClass('remove')" class="nav-item">
                                                <a href="javascript:void(0)" class="nav-link">
                                                    <span class="menuIcon flex-shrink-0">
                                                        <img src="assets/images/menu-icon/header-menu7.svg" alt="s1"
                                                            title="Market Trend" />
                                                    </span>
                                                    <div class="flex-grow-1">
                                                        <h6 class="mb-1 medium">Research Forum</h6>
                                                        <p class="mb-0">
                                                            An economic calendar is a resource that allows
                                                            traders to learn about important.
                                                        </p>
                                                    </div>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </li> -->
                        </ul>
                    </div>
                    <div class="form-inline my-2 my-lg-0">
                        <router-link class="medium f-15 nav-link login white me-lg-3 me-1" to="/login"
                            v-if="!store.user.profile">Login</router-link>
                        <router-link to="/register" class="f-15 medium grd_btn me-3" v-if="!store.user.profile">Register
                        </router-link>
                        <router-link to="/profile" class="f-15 medium grd_btn" v-if="store.user.profile">Profile
                        </router-link>
                        <a href="javascript:void(0)" @click="() => store.logout()" class="ms-4 d-flex align-items-center f-15 text-white logoutPort"
                            v-if="store.user.profile">
                            <vue-feather size="16" class="me-1" type="power"></vue-feather> Logout
                        </a>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
import { useMainStore } from "@/store";

export default {
    setup() {
        const store = useMainStore();
        return { store };
    },
    data() {
        return {
            navCollpase: false,
            toggleMenuOpen: false,
        };
    },
    mounted() {
        this.store.getTrendingIsins();
    },
    methods: {
        toggleBodyClass(addRemoveClass, className) {
            const el = document.body;
            if (addRemoveClass === "addClass") {
                el.classList.add(className);
            } else {
                el.classList.remove(className);
            }
        },
        addClass(getData) {
            const el = document.body;
            if (getData == "remove") {
                if (el.classList.contains("toggleBody")) {
                    el.classList.remove("toggleBody");
                }
            } else {
                if (el.classList.contains("toggleBody")) {
                    el.classList.remove("toggleBody");
                } else {
                    el.classList.add("toggleBody");
                }
            }
        },
    },
};
</script>
