import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import Toast from "vue-toastification";
import staticVars from '@/staticVars'
import * as COMMON_METHODS from './commonFunctions'
import vSelect from "vue-select";
import { defineRule } from 'vee-validate';
import AllRules from '@vee-validate/rules';
import VPagination from "@hennge/vue3-pagination";
//import Datepicker from 'vue3-date-time-picker';
import VueFeather from 'vue-feather';
import vuex_store from '@/store/vuex_store';
import createPopper from '@popperjs/core';
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import norecord from './components/shared/no-record-found.vue';
import '../node_modules/bootstrap/dist/js/bootstrap.min.js'
//Css for npm packages
// import "vue-select/dist/vue-select.css";
import "vue-toastification/dist/index.css";
// import "@hennge/vue3-pagination/dist/vue3-pagination.css";
// import "../public/assets/css/chartiq.css";
// import "../public/assets/css/stx-chart.css";
// import "../public/assets/css/stx-standard.css";
import 'vue3-marquee/dist/style.css'
import '../public/assets/css/font-awesome.min.css'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../public/assets/css/skeleton.css';
import '../public/assets/css/style.css';
import '../public/assets/css/responsive.css';

import PerfectScrollbar from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'

//full screen 
import VueFullscreen from 'vue-fullscreen'
import Vue from 'vue'
import slider from "vue3-slider"
import "vue-select/dist/vue-select.css";


Object.keys(AllRules).forEach(rule => {
  defineRule(rule, AllRules[rule]);
});


const app = createApp(App)

//Create global variables
app.config.globalProperties.static_vars = staticVars
app.config.globalProperties.COMMON = COMMON_METHODS

//Pinia store with persisted state
const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

//Components for global use
app.use(vuex_store)
app.use(pinia)
app.use(PerfectScrollbar)
app.use(router)
app.use(Toast, {
  position: 'top-right',
  timeout: 3000,
  hideProgressBar: true,
  type: 'success'
});

app.component('v-select', vSelect)
app.component('v-paginate', VPagination)
//app.component('date-picker', Datepicker);
app.component(VueFeather.name, VueFeather);
app.component('no-record', norecord);
app.component('Datepicker', Datepicker);
app.component('vue3-slider', slider);
// app.use(''VueFullscreen);
//App mounted
app.mount('#app')
