import { createRouter, createWebHistory } from 'vue-router'
// import { useMainStore } from '@/store'

//Auth
import Login from '@/views/user/login.vue'
import register from '@/views/user/register.vue'
import forgot_password from '@/views/user/forgot-password.vue'
// import ForgorPassword from '@/views/auth/forgot-password.vue'
// import ResetPassword from '@/views/auth/reset-password.vue'
// import Register from '@/views/auth/register.vue'

//Entry point
import Index from '@/views/index.vue'

//User
// import notfound from '@/views/not-found/not-found'
// import Dashboard from '@/views/home/dashboard'
// import instrument from '@/views/instrument/instrument_description'
// import gdp from '@/views/economic-overview/gdp'
// import economic_overview from '@/views/economic-overview/economic_overview'
// import money_and_price from '@/views/economic-overview/money_and_price'
// import markets from '@/views/economic-overview/markets'
// import business_consumer from '@/views/economic-overview/business_consumer'
// import revenue_and_expenditure from '@/views/economic-overview/revenue_and_expenditure'
// import labour_and_health from '@/views/economic-overview/labour_and_health'
// import forex_and_trade from '@/views/economic-overview/forex_and_trade'
// import latest_news from '@/views/economic-overview/latest_news'
// import news from '@/views/news/news'
// import economic_calendar from '@/views/economic-calendar/economic_calendar'
// import technical_analysis from '@/views/technical-analysis/technical_analysis'
// import chart_patterns from '@/views/chart-patterns/chart_patterns'
// import trade_ideas from '@/views/trade-ideas/trade_ideas'
// import profile from '@/views/profile/profile'
// import other_user_profile from '@/views/profile/other_user_profile'
// import gdp_india_forecase from '@/views/gdp-india-forecase/gdp_india_forecase'
// import summary from '@/views/summary/summary'
// import economy_compare from '@/views/economy-compare/economy_compare'
// import indicator_list from '@/views/indicator/indicator_list'
// import gdp_growth_rate_by_country from '@/views/growth-country/gdp_growth_rate_by_country'
// import market_trend from '@/views/market-trend/market_trend'
// import tools_and_analysis from '@/views/tools-and-analysis/tools_and_analysis'
// import publish_idea from '@/views/publish-idea/publish_idea'
// import market_insights from '@/views/market-insights/market_insights'
// import market from '@/views/market/market'
// import chart from '@/views/charts/index'
// import all_news from '@/views/news/all-news'
// import all_market_insights from '@/views/market-insights/all_market_insights'

//User(lazy loaded)
const notfound = () => import('@/views/not-found/not-found')
const Dashboard = () => import('@/views/home/dashboard')
const instrument = () => import('@/views/instrument/instrument_description')
// const gdp = () => import('@/views/economic-overview/gdp')
const gdp = () => import('@/views/economy-overview/gdp')
const economic_overview = () => import('@/views/economic-overview/economic_overview')
const economy_overview = () => import('@/views/economy-overview/economy_overview')
const money_and_price = () => import('@/views/economy-overview/money_and_price')
const markets = () => import('@/views/economy-overview/markets')
const business_consumer = () => import('@/views/economy-overview/business_consumer')
const revenue_and_expenditure = () => import('@/views/economy-overview/revenue_and_expenditure')
const labour_and_health = () => import('@/views/economy-overview/labour_and_health')
const forex_and_trade = () => import('@/views/economy-overview/forex_and_trade')
const imports_and_exports = () => import('@/views/economy-overview/imports_and_exports')
const latest_news = () => import('@/views/economy-overview/latest_news')
const news = () => import('@/views/news/news')
const news_details = () => import('@/views/news/news_details')
const mobile_news_detail = () => import('@/views/news/mobile_news_detail')
const economic_calendar = () => import('@/views/economic-calendar/economic_calendar')
const technical_analysis = () => import('@/views/technical-analysis/technical_analysis')
const chart_patterns = () => import('@/views/chart-patterns/chart_patterns')
const trade_ideas = () => import('@/views/trade-ideas/trade_ideas')
const profile = () => import('@/views/profile/profile')
const other_user_profile = () => import('@/views/profile/other_user_profile')
const gdp_india_forecase = () => import('@/views/gdp-india-forecase/gdp_india_forecase')
const summary = () => import('@/views/summary/summary')
const economy_compare = () => import('@/views/economy-compare/economy_compare')
const indicator_list = () => import('@/views/indicator/indicator_list')
const gdp_growth_rate_by_country = () => import('@/views/growth-country/gdp_growth_rate_by_country')
const market_trend = () => import('@/views/market-trend/market_trend')
const tools_and_analysis = () => import('@/views/tools-and-analysis/tools_and_analysis')
const publish_idea = () => import('@/views/publish-idea/publish_idea')
const market_insights = () => import('@/views/market-insights/market_insights')
const market = () => import('@/views/market/market')
const chart = () => import('@/views/charts/index')
const all_news = () => import('@/views/news/all-news')
const all_market_insights = () => import('@/views/market-insights/all_market_insights')
const details = () => import('@/views/trade-ideas/trade_ideas_details')
const insight_detail = () => import('@/views/market-insights/insight-detail')


//Tools
// const Tools = () => import('@/views/tools/index.vue')

//Others

const routes = [
  { path: '/:pathMatch(.*)*', component: Dashboard },

  {
    path: '/login',
    component: Login,
    meta: {
      requiresAuth: false
    },
  },
  {
    path: '/register',
    component: register,
    meta: {
      requiresAuth: false
    },
  },
  {
    path: '/forgot-password',
    component: forgot_password,
    meta: {
      requiresAuth: false
    },
  },
  {
    path: '/',
    component: Index,
    meta: {
      requiresAuth: true
    },
    children: [
      { path: '/not-found', component: notfound, name: 'notfound', meta: { requiresAuth: true } },
      { path: '/', component: Dashboard, name: 'Dashboard', meta: { requiresAuth: true } },
      { path: '/instrument-description', component: instrument, name: 'instrument_description', meta: { requiresAuth: true } },
      { path: '/gdp', component: gdp, name: 'gdp', meta: { requiresAuth: true } },
      { path: '/economic-overview', component: economic_overview, name: 'economic_overview', meta: { requiresAuth: true } },
      { path: '/details/:id/:title/:page', component: details, name: 'details', meta: { requiresAuth: true } },
      { path: '/economy-overview', component: economy_overview, name: 'economy_overview', meta: { requiresAuth: true } },
      { path: '/money-and-price', component: money_and_price, name: 'money_and_price', meta: { requiresAuth: true } },
      { path: '/markets', component: markets, name: 'markets', meta: { requiresAuth: true } },
      { path: '/business-consumer', component: business_consumer, name: 'business_consumer', meta: { requiresAuth: true } },
      { path: '/revenue-and-expenditure', component: revenue_and_expenditure, name: 'revenue_and_expenditure', meta: { requiresAuth: true } },
      { path: '/labour-and-health', component: labour_and_health, name: 'labour_and_health', meta: { requiresAuth: true } },
      { path: '/forex-and-trade', component: forex_and_trade, name: 'forex_and_trade', meta: { requiresAuth: true } },
      { path: '/imports-and-exports', component: imports_and_exports, name: 'imports_and_exports', meta: { requiresAuth: true } },
      { path: '/latest-news', component: latest_news, name: 'latest_news', meta: { requiresAuth: true } },
      { path: '/news', component: news, name: 'news', meta: { requiresAuth: true } },
      { path: '/news-detail/:id/:title/:page', component: news_details, name: 'news_details', meta: { requiresAuth: true } },
      { path: '/mobile-news-detail/:id', component: mobile_news_detail, name: 'mobile_news_detail', meta: { requiresAuth: true } },
      { path: '/economic-calendar', component: economic_calendar, name: 'economic_calendar', meta: { requiresAuth: true } },
      { path: '/technical-analysis', component: technical_analysis, name: 'technical_analysis', meta: { requiresAuth: true } },
      { path: '/chart-patterns', component: chart_patterns, name: 'chart_patterns', meta: { requiresAuth: true } },
      { path: '/trade-ideas', component: trade_ideas, name: 'trade_ideas', meta: { requiresAuth: true } },
      { path: '/profile', component: profile, name: 'profile', meta: { requiresAuth: true } },
      { path: '/other-user-profile', component: other_user_profile, name: 'other_user_profile', meta: { requiresAuth: true } },
      { path: '/gdp-detail', component: gdp_india_forecase, name: 'gdp_india_forecase', meta: { requiresAuth: true } },
      { path: '/summary', component: summary, name: 'summary', meta: { requiresAuth: true } },
      { path: '/economy-compare', component: economy_compare, name: 'economy_compare', meta: { requiresAuth: true } },
      { path: '/indicator-list', component: indicator_list, name: 'indicator_list', meta: { requiresAuth: true } },
      { path: '/gdp-growth-rate-by-country', component: gdp_growth_rate_by_country, name: 'gdp_growth_rate_by_country', meta: { requiresAuth: true } },
      { path: '/market-trend', component: market_trend, name: 'market_trend', meta: { requiresAuth: true } },
      { path: '/tools-and-analysis', component: tools_and_analysis, name: 'tools_and_analysis', meta: { requiresAuth: true } },
      { path: '/publish-idea', component: publish_idea, name: 'publish_idea', meta: { requiresAuth: true } },
      { path: '/market-insights', component: market_insights, name: 'market_insights', meta: { requiresAuth: true } },
      { path: '/market', component: market, name: 'market', meta: { requiresAuth: true } },
      { path: '/chart', component: chart, name: 'chart', meta: { requiresAuth: true } },
      { path: '/all-news', component: all_news, name: 'all_news', meta: { requiresAuth: true } },
      { path: '/all-market-insights', component: all_market_insights, name: 'all_market_insights', meta: { requiresAuth: true } },
      { path: '/insight-detail/:id/:title/:page', component: insight_detail, name: 'insight_detail', meta: { requiresAuth: true } },
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from) {
      document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
    // if( from.name != "news_details"){
    // }
  }
})

// router.beforeEach((to) => {
//   if (to.path == '/') return { name: 'Dashboard' }
// })
export default router
