import { defineStore } from 'pinia'
import * as API from '@/api/rx-factory'
import * as ENDPOINTS from '@/api/endpoints'
import router from '@/router'
import { useToast } from 'vue-toastification'
import ToastMessage from '@/components/shared/toast_message.vue'
import moment from 'moment'

const toast = useToast()

const successHandler = (message, redirect) => {
  toast(message, {
    timeout: 4000
  })
  if (redirect) {
    if (redirect && redirect == 'back') {
      router.back()
    } else {
      router.push(`/${redirect}`)
    }
  }
}

export const useMainStore = defineStore('main', {
  persist: {
    key: 'storekey',
    storage: window.localStorage,
    paths: ['user', 'newsList', 'financialContent', 'trendingNews','economicGDPData','economicGdpHistory','userProfile','anotherUserProfile','tradeIdeasList'],
  },
  state: () => ({
    loading: false,
    user: {},
    transactionID: null,
    newsList: [],
    ForexNews: [],
    CryptoNews: [],
    IndicesNews: [],
    CommoditiesNews: [],
    usNews: [],
    euNews: [],
    asiaNews: [],
    bondNews: [],
    ReadNews: [],
    countryNews: [],
    alleconomyNews: [],
    financialContent: [],
    treandingInsight: [],
    calendarData: [],
    calendarTickerData: {},
    popularIdeas: {},
    popularNews: [],
    economicOverview: {},
    ReadfinancialContent: [],
    trendingNews: [],
    tradeIdeasList: {},
    monthlyReturnData: [],
    chartData: [],
    trendingIsins: {},
    EconomyOverview : {},
    selectedCountry : null,
    economicGDPData : {},
    selectedIndicator : null,
    economicGdpHistory : {},
    economicMarketHistory : {},
    userProfile : {},
    anotherUserProfile : {},
    tradeDetail : {},
    ideaComments : {},
    userFollowers : {},
    userFollowing : {},
    instrumentNews : {},
    trendingisinsList  : {},
    trendingSymbolEvents : {},
  }),
  getters: {
    allMarketInsights() {
      return this.financialContent.records.concat(this.ReadfinancialContent.records).concat(this.treandingInsight.records)
    }
  },
  actions: {
    callNewsList(params = {}, loader = false) {
      return new Promise((resolve, reject) => {
        if (loader) { this.loading = true }
        API.post(ENDPOINTS.EP_GET_NEWS, params).then(response => {
          this.loading = false
          if (params['economy_country'] && params['economy_country'] == 'US') {
            this.usNews = response
          }
          if (params['economy_country'] && params['economy_country'] == 'Europe') {
            this.euNews = response
          }
          if (params['economy_country'] && params['economy_country'] == 'Asia') {
            this.asiaNews = response
          }
          if (params['category_type'] && params['category_type'] == 'FOREX') {
            if (params['page'] && params['page'] > 1) {
              let json = {};
              json['records'] = [...this.ForexNews['records'], ...response['records']]
              json['totalRecords'] = response['totalRecords']
              this.ForexNews = json
            } else {
              this.ForexNews = response
            }
          }
          if (params['category_type'] && params['category_type'] == 'CRYPTO') {
            if (params['page'] && params['page'] > 1) {
              let json = {};
              json['records'] = [...this.CryptoNews['records'], ...response['records']]
              json['totalRecords'] = response['totalRecords']
              this.CryptoNews = json
            } else {
              this.CryptoNews = response
            }
          }
          if (params['category_type'] && params['category_type'] == 'INDICES') {
            if (params['page'] && params['page'] > 1) {
              let json = {};
              json['records'] = [...this.IndicesNews['records'], ...response['records']]
              json['totalRecords'] = response['totalRecords']
              this.IndicesNews = json
            } else {
              this.IndicesNews = response
            }
          }
          if (params['category_type'] && params['category_type'] == 'COMMODITIES') {
            if (params['page'] && params['page'] > 1) {
              let json = {};
              json['records'] = [...this.CommoditiesNews['records'], ...response['records']]
              json['totalRecords'] = response['totalRecords']
              this.CommoditiesNews = json
            } else {
              this.CommoditiesNews = response
            }
          }
          if (params['category_type'] && params['category_type'] == 'BONDS') {
            if (params['page'] && params['page'] > 1) {
              let json = {};
              json['records'] = [...this.bondNews['records'], ...response['records']]
              json['totalRecords'] = response['totalRecords']
              this.bondNews = json
            } else {
              this.bondNews = response
            }
          }
          if (params['category_type'] && params['category_type'] == 'ECONOMY') {
            if (params['page'] && params['page'] > 1) {
              let json = {};
              json['records'] = [...this.alleconomyNews['records'], ...response['records']]
              json['totalRecords'] = response['totalRecords']
              this.alleconomyNews = json
            } else {
              this.alleconomyNews = response
            }
          }
          if (params['type'] && params['type'] == 'most_read') {
            if (params['page'] && params['page'] > 1) {
              let json = {};
              json['records'] = [...this.ReadNews['records'], ...response['records']]
              json['totalRecords'] = response['totalRecords']
              this.ReadNews = json
            } else {
              this.ReadNews = response
            }
          }
          if (params['country'] && params['country'].length > 0) {
            if (params['page'] && params['page'] > 1) {
              let json = {};
              json['records'] = [...this.newsList['records'], ...response['records']]
              json['totalRecords'] = response['totalRecords']
              this.newsList = json
            } else {
              this.newsList = response
            }
          }
          if(params['tags'] && params['tags'].length > 0){
            if (params['page'] && params['page'] > 1) {
              let json = {};
              json['records'] = [...this.instrumentNews['records'], ...response['records']]
              json['totalRecords'] = response['totalRecords']
              this.instrumentNews = json
            } else {
              this.instrumentNews = response
            }
          }
          if (!params['category_type'] && !params['type'] && !params['country'] && !params['economy_country'] && !params['tags']) {
            if (params['page'] && params['page'] > 1) {
              let json = {};
              json['records'] = [...this.newsList['records'], ...response['records']]
              json['totalRecords'] = response['totalRecords']
              this.newsList = json
            } else {
              this.newsList = response
            }
          }
          resolve(response)
        }).catch(error => {
          this.loading = false
          reject(error)
        })
      })
    },
    callNewsDetails(params = {}, loader = false) {
      return new Promise((resolve, reject) => {
        if (loader) { this.loading = true }
        API.post(ENDPOINTS.EP_GET_NEWS_DETAILS, params).then(response => {
          this.loading = false
          resolve(response)
        }).catch(error => {
          this.loading = false
          reject(error)
        })
      })
    },
    callTrendingNews(params = {}, loader = false) {
      return new Promise((resolve, reject) => {
        if (loader) { this.loading = true }
        const inputjson = JSON.parse(JSON.stringify(params));
        delete params['from'];
        API.post(ENDPOINTS.EP_GET_TRENDING_NEWS, params).then(response => {
          this.loading = false
          if (inputjson['from'] && inputjson['from'] == 'All-News') {
            if (response.totalRecords && response.totalRecords > 0) {
              if (inputjson['page'] && inputjson['page'] > 1) {
                if (inputjson['category_type'] && inputjson['category_type'] == 'ECONOMY') {
                  let json = {};
                  response['records'] = response['records'].map(val => val.details)
                  json['records'] = [...this.alleconomyNews['records'], ...response['records']]
                  json['totalRecords'] = response['totalRecords']
                  this.alleconomyNews = { 'records': json['records'], 'totalRecords': json['totalRecords'] }
                } else {
                  let json = {};
                  response['records'] = response['records'].map(val => val.details)
                  json['records'] = [...this.trendingNews['records'], ...response['records']]
                  json['totalRecords'] = response['totalRecords']
                  this.trendingNews = { 'records': json['records'], 'totalRecords': json['totalRecords'] }
                }
              } else {
                let array = response.records.map(val => val.details)
                if (inputjson['category_type'] && inputjson['category_type'] == 'ECONOMY') {
                  this.alleconomyNews = { 'records': array, 'totalRecords': response.totalRecords }
                } else {
                  this.trendingNews = { 'records': array, 'totalRecords': response.totalRecords }
                }
              }
            } else {
              this.trendingNews = response
              if (inputjson['category_type'] && inputjson['category_type'] == 'ECONOMY') {
                this.alleconomyNews = response
              }
            }
          } else {
            this.trendingNews = response
          }
          resolve(response)
        }).catch(error => {
          this.loading = false
          reject(error)
        })
      })
    },
    callFinancialContent(params = {}, loader = false) {
      return new Promise((resolve, reject) => {
        if (loader) { this.loading = true }
        API.post(ENDPOINTS.EP_GET_FINANCIAL_CONTENT, params).then(response => {
          this.loading = false
          if (params['type'] && params['type'] == 'most_read') {
            this.ReadfinancialContent = response
          } else {
            if (params['page'] && params['page'] > 1) {
              let json = {};
              json['records'] = [...this.financialContent['records'], ...response['records']]
              json['totalRecords'] = response['totalRecords']
              this.financialContent = json
            } else {
              this.financialContent = response
            }
          }
          resolve(response)
        }).catch(error => {
          this.loading = false
          reject(error)
        })
      })
    },
    callTreandingFinancialContent(params = {}, loader = false) {
      return new Promise((resolve, reject) => {
        if (loader) { this.loading = true }
        API.post(ENDPOINTS.EP_GET_TRANDING_FINANCIAL_CONTENT, params).then(response => {
          this.loading = false
          if (params['page'] && params['page'] > 1) {
            if (Object.keys(response).length > 0 && response.records.length > 0) {
              let json = {};
              response['records'] = response['records'].map(val => val.details)
              json['records'] = [...this.treandingInsight['records'], ...response['records']]
              json['totalRecords'] = response['totalRecords']
              this.treandingInsight = json
            }
          }else{
            if (Object.keys(response).length > 0 && response.records.length > 0) {
              let array = response.records.map(val => val.details)
              this.treandingInsight = { 'records': array, 'totalRecords': response.totalRecords }
            } else{
              this.treandingInsight = response
            }
          }
          resolve(response)
        }).catch(error => {
          this.loading = false
          reject(error)
        })
      })
    },
    callEconomicOverview(params = {}, loader = false) {
      return new Promise((resolve, reject) => {
        if (loader) { this.loading = true }
        API.post(ENDPOINTS.EP_GET_ECONOMIC_OVERVIEW, params).then(response => {
          this.loading = false
          if (Object.keys(response).length > 0) {
            for (let key in response) {
              response[key] = response[key].map(val => JSON.parse(val))
              response[key].sort(function (a, b) {
                return a.LatestValue - b.LatestValue;
              });
            }
          }
          this.economicOverview = response
          resolve(response)
        }).catch(error => {
          this.loading = false
          reject(error)
        })
      })
    },
    callFinancialContentDetail(params = {}, loader = false) {
      return new Promise((resolve, reject) => {
        if (loader) { this.loading = true }
        API.post(ENDPOINTS.EP_GET_FINANCIAL_CONTENT_DETAIL, params).then(response => {
          this.loading = false
          resolve(response)
        }).catch(error => {
          this.loading = false
          reject(error)
        })
      })
    },
    getCalendar(params = {}, loader = false) {
      return new Promise((resolve, reject) => {
        if (loader) { this.loading = true }
        API.post(ENDPOINTS.EP_CALENDAR, params).then(response => {
          const original = JSON.parse(JSON.stringify(response))
          // const d = original.reverse()
          const temp: any = [];
          // temp.sort((a, b) => {
          //   return moment(a.date) - moment(b.date)
          // })
          original.map((i, index) => {
            const ind = temp.findIndex((j: any) => moment(j.date).format('YYYY-MM-DD') == moment(i.date).format('YYYY-MM-DD'))
            if (ind > -1) {
              if (temp[ind].items) {
                temp[ind].items.push(i)
              } else {
                temp[ind].items = [i]
              }
            } else {
              temp.push({ date: moment(i.date).format('YYYY-MM-DD'), items: [i] })
            }
          });
          this.loading = false
          this.calendarData = temp
          resolve(response)
        }).catch(error => {
          this.loading = false
          reject(error)
        })
      })
    },
    getCalendarTickerHistory(params = {}, loader = false) {
      return new Promise((resolve, reject) => {
        if (loader) { this.loading = true }
        API.post(ENDPOINTS.EP_CALENDAR_TICKER_HISTORY, params).then(response => {
          this.loading = false
          if (response.chartdata && response.chartdata.length) {
            response.chartdata.map(i => {
              i.date = new Date(i.date).getTime()
            })
          }
          this.calendarTickerData = response
          resolve(response)
        }).catch(error => {
          this.loading = false
          reject(error)
        })
      })
    },
    getProfile() {
      return new Promise((resolve, reject) => {

      })
    },
    getTrades() {
      return new Promise((resolve, reject) => {

      })
    },
    callCreateTradeIdea(params = {}, loader = false,_that) {
      return new Promise((resolve, reject) => {
        if (loader) { this.loading = true }
        API.post(ENDPOINTS.EP_CREATE_TRADE_IDEA, params).then(response => {
          this.loading = false
          //successHandler(`Post Idea Successfully`, '')
          _that.reset()
          resolve(response)
        }).catch(error => {
          this.loading = false
          reject(error)
        })
      })
    },
    callPopularIdeas(params = {}, loader = false) {
      return new Promise((resolve, reject) => {
        if (loader) { this.loading = true }
        API.post(ENDPOINTS.EP_GET_POPULAR_IDEAS, params).then(response => {
          this.loading = false
          this.popularIdeas = response
          resolve(response)
        }).catch(error => {
          this.loading = false
          reject(error)
        })
      })
    },
    callPopularNews(params = {}, loader = false) {
      return new Promise((resolve, reject) => {
        if (loader) { this.loading = true }
        const inputjson = JSON.parse(JSON.stringify(params));
        delete params['from'];
        API.post(ENDPOINTS.EP_GET_POPULAR_NEWS, params).then(response => {
          this.loading = false
          if (inputjson['from'] && inputjson['from'] == 'All-News') {
            //this.popularNews = response.records.map(val => val.details)
            if (response.totalRecords && response.totalRecords > 0) {
              if (inputjson['page'] && inputjson['page'] > 1) {
                let json = {};
                response['records'] = response['records'].map(val => val.details[0])
                json['records'] = [...this.popularNews['records'], ...response['records']]
                json['totalRecords'] = response['totalRecords']
                this.popularNews = { 'records': json['records'], 'totalRecords': json['totalRecords'] }
              } else {
                let array = response.records.map(val => val.details[0])
                this.popularNews = { 'records': array, 'totalRecords': response.totalRecords }
              }
            }
          } else {
            this.popularNews = response
          }
          resolve(response)
        }).catch(error => {
          this.loading = false
          reject(error)
        })
      })
    },
    login(params = {}, loader = false,_that) {
      return new Promise((resolve, reject) => {
        if (loader) { this.loading = true }
          const inputjson = JSON.parse(JSON.stringify(params));
          delete params['from'];
        API.post(ENDPOINTS.EP_LOGIN, params).then(response => {
          this.loading = false
          this.user = response.user
          resolve(response)
          if(!inputjson['from']){
            router.replace('/')
            //successHandler(`Login Successfully`, '')
          }else if(inputjson['from'] =='PostIdea'){
            _that.loginform = {
              email: "",
              password: "",
            }
            _that.form.data.symbol = _that.loadChart;
            _that.form.data.category = _that.instruments[_that.loadChart].other.type
            _that.form.data.documentBase64 = _that.stxx.chart.canvas.toDataURL();
            _that.form.data.document = _that.dataURLtoFile(
                _that.form.data.documentBase64,
                "chart_idea.png"
            );
            _that.form.data.time_interval = _that.defaultChartInterval;
            _that.showPopup = 1
          }else if(inputjson['from'] =='TradeIdeaList'){
            _that.showPopup = ''
          }else if(inputjson['from'] =='profile'){
            _that.$parent.showPopup = ''
          }
        }).catch(error => {
          this.loading = false
          reject(error)
        })
      })
    },
    createLead(params = {}, loader = false) {
      return new Promise((resolve, reject) => {
        if (loader) { this.loading = true }
        API.post(ENDPOINTS.EP_CREATE_REGISTRATION_LEAD, params).then(response => {
          this.loading = false
          this.transactionID = response.transaction_id
          resolve(response)
        }).catch(error => {
          this.loading = false
          reject(error)
        })
      })
    },
    logout() {
      this.loading = true
      setTimeout(() => {
        //this.$reset()
        //router.replace('/login')
        this.user = {}
        this.loading = false
      }, 1500)
    },
    register(params = {}, loader = false) {
      return new Promise((resolve, reject) => {
        if (loader) { this.loading = true }
        API.post(ENDPOINTS.EP_REGISTER, params).then(response => {
          this.loading = false
          this.user = response.user
          router.replace('/')
          resolve(response)
        }).catch(error => {
          this.loading = false
          reject(error)
        })
      })
    },
    forgotPassword(params = {}, loader = false) {
      return new Promise((resolve, reject) => {
        if (loader) { this.loading = true }
        API.post(ENDPOINTS.EP_FORGOT_PASSWORD, params).then(response => {
          this.loading = false
          resolve(response)
        }).catch(error => {
          this.loading = false
          reject(error)
        })
      })
    },
    forgotPasswordVerify(params = {}, loader = false) {
      return new Promise((resolve, reject) => {
        if (loader) { this.loading = true }
        API.post(ENDPOINTS.EP_VERIFY_FORGOT_PASSWORD, params).then(response => {
          this.loading = false
          resolve(response)
        }).catch(error => {
          this.loading = false
          reject(error)
        })
      })
    },
    callTradeIdeasList(params = {}, loader = false) {
      return new Promise((resolve, reject) => {
        if (loader) { this.loading = true }
        API.post(ENDPOINTS.EP_GET_TRADE_IDEAS_LIST, params).then(response => {
          this.loading = false
          if (params['page'] && params['page'] > 1) {
            if (response.records && response.records.length > 0) {
              let json = {};
              json['records'] = [...this.tradeIdeasList['records'], ...response['records']]
              json['totalRecords'] = response['totalRecords']
              this.tradeIdeasList = json
            } else {
              this.tradeIdeasList = response
            }
          } else {
            this.tradeIdeasList = response
          }
          resolve(response)
        }).catch(error => {
          this.loading = false
          reject(error)
        })
      })
    },
    callTradeIdeasDetail(params = {}, loader = false) {
      return new Promise((resolve, reject) => {
        if (loader) { this.loading = true }
        API.post(ENDPOINTS.EP_GET_TRADE_IDEA_DETAIL, params).then(response => {
          this.loading = false
          this.tradeDetail = response
          resolve(response)
        }).catch(error => {
          this.loading = false
          reject(error)
        })
      })
    },
    getChartData(params = {}, loader = false) {
      return new Promise((resolve, reject) => {
        if (loader) { this.loading = true }
        let link = params['link']
        delete params['link']
        API.get(link, params).then(response => {
          this.loading = false
          if (params['interval'] && params['interval'] == '15m') {
            this.chartData = response.reverse().slice(0, 500)
          } else {
            this.monthlyReturnData = response.reverse()
          }
          resolve(response)
        }).catch(error => {
          this.loading = false
          reject(error)
        })
      })
    },
    getUserProfile(params = {}, loader = false) {
      return new Promise((resolve, reject) => {
        if (loader) { this.loading = true }
        API.post(ENDPOINTS.EP_GET_USER_PROFILE, params).then(response => {
          this.loading = false
          this.userProfile = response[0]
          resolve(response)
        }).catch(error => {
          this.loading = false
          reject(error)
        })
      })
    },
    getAnotherUserProfile(params = {}, loader = false) {
      return new Promise((resolve, reject) => {
        if (loader) { this.loading = true }
        API.post(ENDPOINTS.EP_GET_ANOTHER_USER_PROFILE, params).then(response => {
          this.loading = false
          this.anotherUserProfile = response[0]
          resolve(response)
        }).catch(error => {
          this.loading = false
          reject(error)
        })
      })
    },
    getTrendingIsins(params = {}, loader = false) {
      return new Promise((resolve, reject) => {
        if (loader) { this.loading = true }
        API.post(ENDPOINTS.EP_TRENDING_ISINS, params).then(response => {
          this.loading = false
          this.trendingIsins = response
          resolve(response)
        }).catch(error => {
          this.loading = false
          reject(error)
        })
      })
    },
    getCountryEconomyData(params = {}, loader = false) {
      return new Promise((resolve, reject) => {
        if (loader) { this.loading = true }
        API.post(ENDPOINTS.EP_GET_COUNTRY_ECONOMY_DATA, params).then(response => {
          this.loading = false
          if(params['group']){
            this.economicGDPData = response
          }else{
            this.EconomyOverview = response
          }
          resolve(response)
        }).catch(error => {
          this.loading = false
          reject(error)
        })
      })
    },
    getCountryEconomyHistory(params = {}, loader = false) {
      return new Promise((resolve, reject) => {
        if (loader) { this.loading = true }
        API.post(ENDPOINTS.EP_GET_COUNTRY_ECONOMY_HISTORY, params).then(response => {
          this.loading = false
          this.economicGdpHistory = response
          resolve(response)
        }).catch(error => {
          this.loading = false
          reject(error)
        })
      })
    },
    getCountryEconomyMarketHistory(params = {}, loader = false) {
      return new Promise((resolve, reject) => {
        if (loader) { this.loading = true }
        API.post(ENDPOINTS.EP_GET_COUNTRY_ECONOMY_MARKET_HISTORY, params).then(response => {
          this.loading = false
          this.economicMarketHistory = response
          resolve(response)
        }).catch(error => {
          this.loading = false
          reject(error)
        })
      })
    },
    callUpdateIdeas(params = {}, loader = false) {
      return new Promise((resolve, reject) => {
        if (loader) { this.loading = true }
           const inputjson = JSON.parse(JSON.stringify(params));
          delete params['page'];
        API.post(ENDPOINTS.EP_UPDATE_IDEAS, params).then(response => {
          this.loading = false
          resolve(response)
        }).catch(error => {
          this.loading = false
          reject(error)
        })
      })
    },
    addComment(params = {}, loader = false,_that) {
      return new Promise((resolve, reject) => {
        if (loader) { this.loading = true }
        API.post(ENDPOINTS.EP_ADD_COMMENT, params).then(response => {
          this.loading = false
           //_that.comment = ''
           // if(Object.keys(this.tradeDetail[0]).length){
           //  this.tradeDetail[0].comment_count = parseInt(this.tradeDetail[0].comment_count)+1
           // }
           let json = {
            "trade_id":params['trade_idea_id'],
            "page":1,
            "perPage":10,
            "sort" : {"field" : "trade_idea_comments.created", "type" : "DESC"},
            "logged_user_id" : this.user.profile.user_id
           }
           this.getIdeasComments(json,false)
          //successHandler(`Add Comment Successfully`, '')
          resolve(response)
        }).catch(error => {
          this.loading = false
          reject(error)
        })
      })
    },
    getIdeasComments(params = {}, loader = false) {
      return new Promise((resolve, reject) => {
        if (loader) { this.loading = true }
        API.post(ENDPOINTS.EP_GET_IDEAS_COMMENTS, params).then(response => {
          this.loading = false
          if (params['page'] && parseInt(params['page']) > 1) {
              let json = {};
              json['records'] = [...this.ideaComments['records'], ...response['records']]
              json['totalRecords'] = response['totalRecords']
              this.ideaComments = json
          }else{
            this.ideaComments = response
          }
          resolve(response)
        }).catch(error => {
          this.loading = false
          reject(error)
        })
      })
    },
    callFollowUser(params = {}, loader = false) {
      return new Promise((resolve, reject) => {
        if (loader) { this.loading = true }
        API.post(ENDPOINTS.EP_FOLLOW_USER, params).then(response => {
          this.loading = false
          resolve(response)
        }).catch(error => {
          this.loading = false
          reject(error)
        })
      })
    },
    callUnFollowUser(params = {}, loader = false) {
      return new Promise((resolve, reject) => {
        if (loader) { this.loading = true }
        API.post(ENDPOINTS.EP_UNFOLLOW_USER, params).then(response => {
          this.loading = false
          resolve(response)
        }).catch(error => {
          this.loading = false
          reject(error)
        })
      })
    },
    getUserFollowers(params = {}, loader = false) {
      return new Promise((resolve, reject) => {
        if (loader) { this.loading = true }
          const inputjson = JSON.parse(JSON.stringify(params));
        API.post(ENDPOINTS.EP_USER_FOLLOWERS, params).then(response => {
          this.loading = false
          if(inputjson.type && inputjson.type == 'follower'){
            this.userFollowers = response
          }else if(inputjson.type && inputjson.type == 'following'){
            this.userFollowing = response
          }
          resolve(response)
        }).catch(error => {
          this.loading = false
          reject(error)
        })
      })
    },
    updateUserProfile(params = {}, loader = false,_that) {
      return new Promise((resolve, reject) => {
        if (loader) { this.loading = true }
        API.post(ENDPOINTS.EP_UPDATE_USER_PROFILE, params).then(response => {
          this.loading = false
          _that.reset()
          resolve(response)
        }).catch(error => {
          this.loading = false
          reject(error)
        })
      })
    },
    callTrendingSymbolEvents(params = {}, loader = false) {
      return new Promise((resolve, reject) => {
        if (loader) { this.loading = true }
        API.post(ENDPOINTS.EP_GET_TRENDING_SYMBOL_EVENTS, params).then(response => {
          this.loading = false
          this.trendingSymbolEvents = response
          resolve(response)
        }).catch(error => {
          this.loading = false
          reject(error)
        })
      })
    },
    callTrendingIsinsList(params = {}, loader = false) {
      return new Promise((resolve, reject) => {
        if (loader) { this.loading = true }
        API.post(ENDPOINTS.EP_GET_TRENDING_ISINS_LIST, params).then(response => {
          this.loading = false
          this.trendingisinsList = response
          resolve(response)
        }).catch(error => {
          this.loading = false
          reject(error)
        })
      })
    },
    
  }
})

/*Promise.all([getTrades(), getProfile()]).then( response => {
  response[0]
})*/
