export default {
  classifications: [
    "Trend Analysis",
    "Technical Indicator",
    "Chart Pattern",
    "Wave Analysis",
    "Gann",
    "Fibonacci",
    "Harmonic Pattern",
    "Candlestick Pattern"
  ],
  tradeIdeasFilter: [
    "Currencies",
    "Crypto",
    "Commodities",
    "Indices",
    "Stocks"
  ],
  isinids: {
    home: {
      forex: [
        { label: "EURUSD", value: "EURUSD" },
        { label: "USDCAD", value: "USDCAD" },
        { label: "GBPUSD", value: "GBPUSD" },
        { label: "USDJPY", value: "USDJPY" },
        { label: "EURGBP", value: "EURGBP" },
        { label: "GBPJPY", value: "GBPJPY" },
        { label: "EURJPY", value: "EURJPY" },
        { label: "USDCHF", value: "USDCHF" },
        { label: "AUDUSD", value: "AUDUSD" },
        { label: "NZDUSD", value: "NZDUSD" },
        { label: "USDMXN", value: "USDMXN" },
      ],
      crypto: [
        { label: "BTC-USD", value: "BTC-USD" },
        { label: "ETH-USD", value: "ETH-USD" },
        { label: "LTC-USD", value: "LTC-USD" },
        { label: "XRP-USD", value: "XRP-USD" },
        { label: "ETC-USD", value: "ETC-USD" },
        { label: "ADA-USD", value: "ADA-USD" },
        { label: "NEO-USD", value: "NEO-USD" },
        { label: "XLM-USD", value: "XLM-USD" },
        { label: "XEM-USDT", value: "XEM-USDT" },
      ],
      commodities: [
        { label: "XAUUSD", value: "XAUUSD" },
        { label: "USOUSD", value: "USOUSD" },
        { label: "XAGUSD", value: "XAGUSD" },
        { label: "XNGUSD", value: "XNGUSD" },
        // s
        { label: "XAUAUD", value: "XAUAUD" },
        { label: "XAUEUR", value: "XAUEUR" },
        { label: "XAGAUD", value: "XAGAUD" },
      ],
      indices: [
        {
          label: "100GBP", value: "UK100"
        },
        { label: "F40EUR", value: "FCHI" },
        {
          label: "D30EUR", value: "GDAXIM"
        },
        {
          label: "U30USD", value: "WS30M"
        },
        {
          label: "SPXUSD", value: "SPXM"
        },
        {
          label: "NASUSD", value: "NDXM"
        },
        {
          label: "225JPY", value: "J225"
        },
        {
          label: "200AUD", value: "AUS200"
        },
        {
          label: "E50EUR", value: "STOXX50E"
        },
        { label: "E35EUR", value: "E35EUR" },
      ],
      stocks: [
        { label: "AAPL", value: "AAPL" },
        { label: "MSFT", value: "MSFT" },
        { label: "AMZN", value: "AMZN" },
        { label: "TSLA", value: "TSLA" },
        { label: "GOOG", value: "GOOG" },
        { label: "GOOGL", value: "GOOGL" },
        { label: "META", value: "META" },
        { label: "NVDA", value: "NVDA" },
        { label: "PEP", value: "PEP" },
        { label: "COST", value: "COST" },
      ],
    }
  },

  crossPairs: [//exotic list
    'USDHKD',
    'EURHKD',
    'NZDSGD',
    'USDRUB',
    'GBPSGD',
    'GBPHKD',
    'EURSGD',
    'USDZAR',
    'USDTRY',
    'USDSGD',
    'USDSEK',
    'USDPLN',
    'USDNOK',
    'EURCZK',
    'EURDKK',
    'USDCZK',
    'USDCNH',
    'EURNOK',
    'EURPLN',
    'EURSEK',
    'EURTRY',
    'NOKSEK',
    'GBPZAR',
    'GBPTRY',
    'GBPDKK',
    'GBPSEK',
    'GBPPLN',
    'GBPNOK',
    'USDDKK',
    'EURMXN',
    'EURZAR',
    'USDMXN',
    'USDHUF',
    'EURHUF',
    'GBPHUF',
    'EURRUB',
    'USDRON',
    'EURRON',
    'USDTHB',
    'USDILS',
    'GBPCZK',
    'GBPMXN'
  ],
  timezones: [
    { value: "-12:00", label: '(GMT -12:00) Eniwetok, Kwajalein' },
    { value: "-11:00", label: '(GMT -11:00) Midway Island, Samoa' },
    { value: "-10:00", label: '(GMT -10:00) Hawaii' },
    { value: "-09:50", label: '(GMT -9:30) Taiohae' },
    { value: "-09:00", label: '(GMT -9:00) Alaska' },
    { value: "-08:00", label: '(GMT -8:00) Pacific Time (US &amp; Canada)' },
    { value: "-07:00", label: '(GMT -7:00) Mountain Time (US &amp; Canada)' },
    { value: "-06:00", label: '(GMT -6:00) Central Time (US &amp; Canada), Mexico City' },
    { value: "-05:00", label: '(GMT -5:00) Eastern Time (US &amp; Canada), Bogota, Lima' },
    { value: "-04:50", label: '(GMT -4:30) Caracas' },
    { value: "-04:00", label: '(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz' },
    { value: "-03:50", label: '(GMT -3:30) Newfoundland' },
    { value: "-03:00", label: '(GMT -3:00) Brazil, Buenos Aires, Georgetown' },
    { value: "-02:00", label: '(GMT -2:00) Mid-Atlantic' },
    { value: "-01:00", label: '(GMT -1:00) Azores, Cape Verde Islands' },
    { value: "+00:00", label: '(GMT) Western Europe Time, London, Lisbon, Casablanca' },
    { value: "+01:00", label: '(GMT +1:00) Brussels, Copenhagen, Madrid, Paris' },
    { value: "+02:00", label: '(GMT +2:00) Kaliningrad, South Africa' },
    { value: "+03:00", label: '(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg' },
    { value: "+03:50", label: '(GMT +3:30) Tehran' },
    { value: "+04:00", label: '(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi' },
    { value: "+04:50", label: '(GMT +4:30) Kabul' },
    { value: "+05:00", label: '(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent' },
    { value: "+05:50", label: '(GMT +5:30) Bombay, Calcutta, Madras, New Delhi' },
    { value: "+05:75", label: '(GMT +5:45) Kathmandu, Pokhara' },
    { value: "+06:00", label: '(GMT +6:00) Almaty, Dhaka, Colombo' },
    { value: "+06:50", label: '(GMT +6:30) Yangon, Mandalay' },
    { value: "+07:00", label: '(GMT +7:00) Bangkok, Hanoi, Jakarta' },
    { value: "+08:00", label: '(GMT +8:00) Beijing, Perth, Singapore, Hong Kong' },
    { value: "+08:75", label: '(GMT +8:45) Eucla' },
    { value: "+09:00", label: '(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk' },
    { value: "+09:50", label: '(GMT +9:30) Adelaide, Darwin' },
    { value: "+10:00", label: '(GMT +10:00) Eastern Australia, Guam, Vladivostok' },
    { value: "+10:50", label: '(GMT +10:30) Lord Howe Island' },
    { value: "+11:00", label: '(GMT +11:00) Magadan, Solomon Islands, New Caledonia' },
    { value: "+11:50", label: '(GMT +11:30) Norfolk Island' },
    { value: "+12:00", label: '(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka' },
    { value: "+12:75", label: '(GMT +12:45) Chatham Islands' },
    { value: "+13:00", label: '(GMT +13:00) Apia, Nukualofa' },
    { value: "+14:00", label: '(GMT +14:00) Line Islands, Tokelau' },
  ],
  timezonesCalendar: [
    { label: "System Default", value: 0 },
    { label: "European Central Time(GMT +1)", value: 60 },
    { label: "Eastern European Time(GMT +2)", value: 120 },
    { label: "Eastern African Time(GMT +3)", value: 180 },
    { label: "Middle East Time(GMT +3:30)", value: 210 },
    { label: "Near East Time(GMT +4)", value: 240 },
    { label: "Pakistan Lahore Time(GMT +5)", value: 300 },
    { label: "India Standard Time(GMT +5:30)", value: 330 },
    { label: "Bangladesh Standard Time(GMT +6)", value: 360 },
    { label: "Vietnam Standard Time(GMT +7)", value: 420 },
    { label: "China Taiwan Time(GMT +8)", value: 480 },
    { label: "Japan Standard Time(GMT +9)", value: 540 },
    { label: "Australia Central Time(GMT +9:30)", value: 570 },
    { label: "Australia Eastern Time(GMT +10)", value: 600 },
    { label: "Solomon Standard Time(GMT +11)", value: 660 },
    { label: "New Zealand Standard Time(GMT +12)", value: 720 },
    { label: "Midway Islands Time(GMT -11)", value: -660 },
    { label: "Hawaii Standard Time(GMT -10)", value: -600 },
    { label: "Alaska Standard Time(GMT -9)", value: -540 },
    { label: "Pacific Standard Time(GMT -8)", value: -480 },
    { label: "Phoenix Standard Time(GMT -7)", value: -420 },
    { label: "Central Standard Time(GMT -6)", value: -360 },
    { label: "Eastern Standard Time(GMT -5)", value: -300 },
    { label: "Puerto Rico and US Virgin Islands Time(GMT -4)", value: -240 },
    { label: "Canada Newfoundland Time(GMT -3:30)", value: -210 },
    { label: "Brazil Eastern Time(GMT -3)", value: -180 },
    { label: "Central African Time(GMT -1)", value: -60 }
  ],
  economicCalendarCountries: {
    australia: [
      "Australia",
      "Fiji",
      "Kiribati",
      "New Caledonia",
      "New Zealand",
      "Papua New Guinea",
      "Samoa",
      "Solomon Islands",
      "Tonga",
      "Vanuatu",
    ],
    europe: [
      "Albania",
      "Andorra",
      "Austria",
      "Belarus",
      "Belgium",
      "Bosnia and Herzegovina",
      "Bulgaria",
      "Croatia",
      "Cyprus",
      "Czech Republic",
      "Denmark",
      "Estonia",
      "Euro area",
      "Faroe Islands",
      "Finland",
      "France",
      "Germany",
      "Greece",
      "Hungary",
      "Iceland",
      "Ireland",
      "Isle of Man",
      "Italy",
      "Kosovo",
      "Latvia",
      "Liechtenstein",
      "Lithuania",
      "Luxembourg",
      "Macedonia",
      "Malta",
      "Moldova",
      "Monaco",
      "Montenegro",
      "Netherlands",
      "Norway",
      "Poland",
      "Portugal",
      "Romania",
      "Russia",
      "Serbia",
      "Slovakia",
      "Slovenia",
      "Spain",
      "Sweden",
      "Switzerland",
      "Turkey",
      "Ukraine",
      "United Kingdom",
    ],
    america: [
      "Antigua and Barbuda",
      "Argentina",
      "Aruba",
      "Bahamas",
      "Barbados",
      "Belize",
      "Bermuda",
      "Bolivia",
      "Brazil",
      "Canada",
      "Cayman Islands",
      "Chile",
      "Colombia",
      "Costa Rica",
      "Cuba",
      "Dominica",
      "Dominican Republic",
      "Ecuador",
      "El Salvador",
      "Grenada",
      "Guatemala",
      "Guyana",
      "Haiti",
      "Honduras",
      "Jamaica",
      "Mexico",
      "Nicaragua",
      "Panama",
      "Paraguay",
      "Peru",
      "Puerto Rico",
      "Suriname",
      "Trinidad and Tobago",
      "United States",
      "Uruguay",
      "Venezuela",
    ],
    africa: [
      "Algeria",
      "Angola",
      "Benin",
      "Botswana",
      "Burkina Faso",
      "Burundi",
      "Cameroon",
      "Cape Verde",
      "Central African Republic",
      "Chad",
      "Comoros",
      "Congo",
      "Djibouti",
      "Egypt",
      "Equatorial Guinea",
      "Eritrea",
      "Ethiopia",
      "Gabon",
      "Gambia",
      "Ghana",
      "Guinea",
      "Guinea Bissau",
      "Ivory Coast",
      "Kenya",
      "Lesotho",
      "Liberia",
      "Libya",
      "Madagascar",
      "Malawi",
      "Mali",
      "Mauritania",
      "Mauritius",
      "Morocco",
      "Mozambique",
      "Namibia",
      "Niger",
      "Nigeria",
      "Republic of the Congo",
      "Rwanda",
      "Sao Tome and Principe",
      "Senegal",
      "Seychelles",
      "Sierra Leone",
      "Somalia",
      "South Africa",
      "South Sudan",
      "Sudan",
      "Swaziland",
      "Tanzania",
      "Togo",
      "Tunisia",
      "Uganda",
      "Zambia",
      "Zimbabwe",
    ],
    asia: [
      "Afghanistan",
      "Armenia",
      "Azerbaijan",
      "Bahrain",
      "Bangladesh",
      "Bhutan",
      "Brunei",
      "Cambodia",
      "China",
      "East Timor",
      "Georgia",
      "Hong Kong",
      "India",
      "Indonesia",
      "Iran",
      "Iraq",
      "Israel",
      "Japan",
      "Jordan",
      "Kazakhstan",
      "Kuwait",
      "Kyrgyzstan",
      "Laos",
      "Lebanon",
      "Macao",
      "Malaysia",
      "Maldives",
      "Mongolia",
      "Myanmar",
      "Nepal",
      "North Korea",
      "Oman",
      "Palestine",
      "Pakistan",
      "Philippines",
      "Qatar",
      "Saudi Arabia",
      "Singapore",
      "South Korea",
      "Sri Lanka",
      "Syria",
      "Taiwan",
      "Tajikistan",
      "Thailand",
      "Turkmenistan",
      "United Arab Emirates",
      "Uzbekistan",
      "Vietnam",
      "Yemen",
    ],
    major: [
      "United States",
      "Euro Area",
      "China",
      "Japan",
      "Germany",
      "United Kingdom",
      "France",
      "India",
      "Italy",
      "Brazil",
      "Canada",
      "South Korea",
      "Russia",
      "Spain",
      "Australia",
      "Mexico",
      "Indonesia",
      "Turkey",
      "Netherlands",
      "Switzerland",
      "Saudi Arabia",
      "Argentina",
      "South Africa",
      "Singapore",
    ]
  },
  economicOverviewContinents: {
    '1': 'World', '2': 'Europe', '3': 'America', '4': 'Asia', '5': 'Africa', '6': 'Australia', '7': 'G20'
  },
  economyOverviewIndicators: {
    'GDP': 'GDP', 'Growth': 'GDP Annual Growth Rate', 'Rate': 'Interest Rate', 'Inflation': 'Inflation Rate', 'Markets': 'Stock Market', 'Currencies': 'Currency', 'Tax': 'Corporate Tax Rate','COVID-19':'Coronavirus Cases'
  },
  economyIndicatorsWithoutGDP :{
    'Growth': 'GDP Annual Growth Rate', 'Rate': 'Interest Rate', 'Inflation': 'Inflation Rate', 'Markets': 'Stock Market', 'Currencies': 'Currency', 'Tax': 'Corporate Tax Rate','COVID-19':'Coronavirus Cases'
  }
}