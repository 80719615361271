<template>
    <footer class="main-footer blackBG pb-0">
        <div class="container position-relative">
            <div class="scrollTop position-absolute">
                <a href="javascript:void(0)" @click="topFunction()" id="moveUp" class="grd_btn btn_arrow">
                    <vue-feather type="chevron-up"></vue-feather>
                </a>
                <!--  <v-scroll-to-top></v-scroll-to-top> -->
            </div>
            <!-- <div class="row">
                <div class="col-12 col-lg-3 pt-lg-3 list">
                    <div class="footer-logo"><img src="/assets/images/logo.svg" alt="logo" /></div>
                    <ul class="contact-details mb-0 mt-4">
                        <li>
                            <vue-feather type="mail"></vue-feather><a
                                href="mailto:info@portfolios.com">info@portfolios.com</a>
                        </li>
                        <li>
                            <vue-feather type="phone-call"></vue-feather><a href="tell:+91 9876543210"> +91
                                9876543210</a>
                        </li>
                        <li>
                            <i data-feather="map-pin"></i>
                            <div class="address">
                                Finvasia Centre D 179, Sector 74, <br />
                                Mohali Punjab 160055
                            </div>
                            <div class="clear"></div>
                        </li>
                    </ul>
                </div>
                <div class="col-12 col-lg-9">
                    <div class="row">
                        <div class="col-12 col-lg-3 list">
                            <h6 @click.prevent="footerTab = 'Company'" :class="(footerTab == 'Company')?'active':''"
                                class="f-18">Company <vue-feather class="d-lg-none" type="chevron-down"></vue-feather>
                            </h6>
                            <ul class="footer-links">
                                <li>
                                    <router-link to="">About Us</router-link>
                                </li>
                                <li>
                                    <router-link to="">Our Team</router-link>
                                </li>
                                <li>
                                    <router-link to="">Contact Us</router-link>
                                </li>
                                <li>
                                    <router-link to="/money-and-price">Pricing</router-link>
                                </li>
                                <li>
                                    <router-link to="">Testimonials</router-link>
                                </li>
                            </ul>
                        </div>
                        <div class="col-12 col-lg-3 list">
                            <h6 @click.prevent="footerTab = 'Company'" :class="(footerTab == 'Company')?'active':''"
                                class="f-18">Company <vue-feather class="d-lg-none" type="chevron-down"></vue-feather>
                            </h6>
                            <ul class="footer-links">
                                <li>
                                    <router-link to="">About Us</router-link>
                                </li>
                                <li>
                                    <router-link to="">Our Team</router-link>
                                </li>
                                <li>
                                    <router-link to="">Contact Us</router-link>
                                </li>
                                <li>
                                    <router-link to="/money-and-price">Pricing</router-link>
                                </li>
                                <li>
                                    <router-link to="">Testimonials</router-link>
                                </li>
                            </ul>
                        </div>
                        <div class="col-12 col-lg-3 list">
                            <h6 @click.prevent="footerTab = 'Useful'" :class="(footerTab == 'Useful')?'active':''"
                                class="f-18">Useful LInks <vue-feather class="d-lg-none" type="chevron-down">
                                </vue-feather>
                            </h6>
                            <ul class="footer-links">
                                <li>
                                    <router-link to="/chart">Charts</router-link>
                                </li>
                                <li>
                                    <router-link to="/news">News </router-link>
                                </li>
                                <li>
                                    <router-link to="/chart-patterns">Chart Pattern</router-link>
                                </li>
                                <li>
                                    <router-link to="/market-insights">Market Insights</router-link>
                                </li>
                                <li>
                                    <router-link to="/market-trend">Market Trends</router-link>
                                </li>
                                <li>
                                    <router-link to="/economy-overview">Economic Overview</router-link>
                                </li>
                                <li>
                                    <router-link to="/economic-calendar">Economic Calendar </router-link>
                                </li>
                                <li>
                                    <router-link to="tools-and-analysis">Statistical Tools </router-link>
                                </li>
                                <li>
                                    <router-link to="tools-and-analysis">Sentiments Tools </router-link>
                                </li>
                                <li>
                                    <router-link to="">Calculators</router-link>
                                </li>
                            </ul>
                        </div>
                        <div class="col-12 col-lg-3 list">
                            <h6 @click.prevent="footerTab = 'Community'" :class="(footerTab == 'Community')?'active':''"
                                class="f-18">Community <vue-feather class="d-lg-none" type="chevron-down"></vue-feather>
                            </h6>
                            <ul class="footer-links">
                                <li>
                                    <router-link to="">Moderators</router-link>
                                </li>
                                <li>
                                    <router-link to="">Authors</router-link>
                                </li>
                                <li>
                                    <router-link to="">Refer a Friend</router-link>
                                </li>
                                <li>
                                    <router-link to="">Trade Idea </router-link>
                                </li>
                                <li>
                                    <router-link to="">Forum</router-link>
                                </li>
                                <li>
                                    <router-link to="">Research Forum </router-link>
                                </li>
                                <li>
                                    <router-link to="">Squawk Box </router-link>
                                </li>
                            </ul>
                        </div>
                        <div class="col-12 col-lg-3 list">
                            <h6 @click.prevent="footerTab = 'Quick'" :class="(footerTab == 'Quick')?'active':''"
                                class="f-18">Quick Links <vue-feather class="d-lg-none" type="chevron-down">
                                </vue-feather>
                            </h6>
                            <ul class="footer-links">
                                <li>
                                    <router-link to="">Terms of Use</router-link>
                                </li>
                                <li>
                                    <router-link to="">Disclaimers</router-link>
                                </li>
                                <li>
                                    <router-link to="">Privacy Policy</router-link>
                                </li>
                                <li>
                                    <router-link to="">Cookies Policy </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="row"><!--New Footer-->
                <div class="col-12 text-center newFooter">
                    <div class="footer-logo"><img src="/assets/images/logo.svg" alt="logo" /></div>
                    <ul class="d-flex align-items-center justify-content-center flex-wrap">
                        <li><router-link to="/chart">Charts</router-link></li>
                        <li><router-link to="/news">News </router-link></li>
                        <li><router-link to="/market-insights">Market Insights</router-link></li>
                        <li><router-link to="/market-trend">Market Trends</router-link></li>
                        <li><router-link to="/economy-overview">Economy Overview</router-link></li>
                        <li><router-link to="/economic-calendar">Economic Calendar </router-link></li>
                    </ul>
                </div>
                <div class="col-12 border-top px-0 py-3">
                    <div class="copyright-wrap d-flex align-items-center justify-content-center">
                        <div class="copyright">
                            <p class="f-13 mb-0 white">©2022 www.portfolios.com All Rights Reserved</p>
                        </div>
                        <!--  <ul class="social d-flex align-items-center mb-0">
                            <li>
                                <router-link to="" title="Twitter"><vue-feather type="twitter"></vue-feather></router-link>
                            </li>
                            <li>
                                <router-link to="" title="Facebook"><vue-feather type="facebook"></vue-feather></router-link>
                            </li>
                            <li>
                                <router-link to="" title="Linkedin"><vue-feather type="linkedin"></vue-feather></router-link>
                            </li>
                            <li>
                                <router-link to="" title="Instagram"><vue-feather type="instagram"></vue-feather></router-link>
                            </li>
                            <li>
                                <router-link to="" title="Reddit"><i class="fa fa-reddit-alien" aria-hidden="true"></i></router-link>
                            </li>
                            <li>
                                <router-link to="" title="Telegram"><vue-feather type="send"></vue-feather></router-link>
                            </li>
                            <li>
                                <router-link to="" title="Youtube"><vue-feather type="youtube"></vue-feather></router-link>
                            </li>
                        </ul> -->
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
export default {
    data() {
        return {
            footerTab: ''
        }
    },
    methods: {
        topFunction() {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        }
    },
    mounted() {
        var mybutton = document.getElementById("moveUp");
        var header = document.getElementById("stickyHeader");
        var sticky = header.offsetTop;

        window.onscroll = function () { scrollFunction() };

        function scrollFunction() {
            if (window.pageYOffset > sticky) {
                header.classList.add("sticky");
            } else {
                header.classList.remove("sticky");
            }

            if (document.body.scrollTop > 700 || document.documentElement.scrollTop > 700) {
                mybutton.style.display = "flex";
            } else {
                mybutton.style.display = "none";
            }
        }
    },
}
</script>
